import { Settings } from 'luxon';
import { api, lambda } from './axios';
import s3 from './s3';
import { batch, flatten } from './array';
import { SignedSerializedInvoice } from './types';
import { Markets } from '../constants/intl';
import { MarketData } from '../components/Forms/MetadataForm/formConstants';
import { AuCountryData } from '../components/Forms/MetadataForm/market/au/formConstants';

interface UploadUrls {
  [invoiceId: string]: string;
}

interface FileToUpload {
  id: string;
  file: File;
  name: string;
  url: string;
}

export const getUploadUrls = async (
  previousCourierId: string = '',
  files: File[] = []
): Promise<FileToUpload[]> => {
  let courierId = previousCourierId;
  if (courierId === '') {
    courierId = `C-${Date.now()}`;
  }
  const { data: urls } = await api.post<UploadUrls>('/getUploadUrls', {
    courierId,
    count: files.length,
  });
  return Object.keys(urls).map((id, i) => ({
    id,
    file: files[i],
    name: `${id}.pdf`,
    url: urls[id],
  }));
};

interface UploadAllToS3Params {
  files: FileToUpload[];
  setProgress: (progress: number) => void;
  baseProgress: number;
  progressAmount: number;
}

export const uploadAllToS3 = async ({
  files,
  setProgress,
  baseProgress,
  progressAmount,
}: UploadAllToS3Params): Promise<void> => {
  let progress = baseProgress;
  await batch<FileToUpload, void>(
    files,
    async ({ url, file }: FileToUpload): Promise<void> => {
      await s3.upload(url, 'application/pdf', file);
      progress += progressAmount / files.length;
      setProgress(progress);
    },
    3
  );
};

const getCountryTimezone = (market: string, marketData: MarketData): string => {
  if (market !== Markets.AUSTRALIA.iso639) {
    return '';
  }
  const australiaMetadata = marketData[
    Markets.AUSTRALIA.iso639
  ] as AuCountryData;
  return australiaMetadata === undefined ? '' : australiaMetadata.timezone;
};

interface ParseUploadedInvoicesParams {
  invoiceIds: string[];
  courierId: string;
  marketCode: string;
  marketData: MarketData;
  setProgress: (progress: number) => void;
  baseProgress: number;
  progressAmount: number;
}

export const parseUploadedInvoices = async ({
  invoiceIds,
  courierId,
  marketCode,
  marketData,
  setProgress,
  baseProgress,
  progressAmount,
}: ParseUploadedInvoicesParams) => {
  let progress = baseProgress;
  const timezone = getCountryTimezone(marketCode, marketData);
  Settings.defaultZone = timezone;

  const parsedInvoices = await Promise.allSettled(
    invoiceIds.map<Promise<SignedSerializedInvoice[]>>(async (invoiceId) => {
      const response = await lambda.post<SignedSerializedInvoice[]>(
        '/parseInvoices',
        {
          courierId,
          invoiceIds: [invoiceId],
          timezone,
        }
      );
      progress += (progressAmount * 0.75) / invoiceIds.length;
      setProgress(progress);
      return response.data;
    })
  );
  const successfulInvoices = flatten(
    parsedInvoices
      .map((result) => (result.status === 'fulfilled' ? result.value : null))
      .filter((value) => value !== null) as SignedSerializedInvoice[][]
  );
  if (process.env.REACT_APP_PARSE_MODE === 'test') {
    // clean up
  } else {
    await api.post('/saveInvoiceData', successfulInvoices);
  }

  setProgress(baseProgress + progressAmount);
  return successfulInvoices;
};

export const isDuplicate = (
  haystack: SignedSerializedInvoice[],
  needle: string
) => haystack.some(({ hash }) => needle === hash);
