import HighlightWord from './HighlightWord';
import styles from './HighlightText.module.scss';
import { IntlMessage } from '../../utils/types';

interface Props {
  topMessage: IntlMessage;
  bottomMessage: IntlMessage;
  highlightClassName: string;
  bottomClassName: string;
}

const HighlightText = ({
  topMessage,
  bottomMessage,
  highlightClassName,
  bottomClassName,
}: Props) => (
  <div className="text-dark font-weight-800">
    <HighlightWord className={highlightClassName} message={topMessage} />
    <HighlightWord
      className={`${highlightClassName} ${bottomClassName}`}
      containerClassName={styles.bottom}
      message={bottomMessage}
    />
  </div>
);

export default HighlightText;
