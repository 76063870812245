import { Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { FieldInputProps } from 'formik';
import { Styled } from '../../utils/types';

interface Props<T> extends Styled {
  id?: string;
  onChange: FieldInputProps<T>['onChange'];
  onBlur?: FieldInputProps<T>['onBlur'];
  value: FieldInputProps<T>['value'];
  isInvalid?: boolean;
  options?: {
    value: string;
    labelId?: string;
    label?: string;
  }[];
  showPlaceholder?: boolean;
}

const Select = <T extends string | number>({
  id = '',
  className = '',
  value,
  onChange,
  isInvalid = false,
  onBlur = () => {},
  options = [],
  showPlaceholder = true,
}: Props<T>) => {
  const { formatMessage } = useIntl();

  return (
    <Form.Select
      id={id}
      className={`${className} text-dark bg-white`}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      isInvalid={isInvalid}
    >
      {showPlaceholder && (
        <option value="">
          {formatMessage({ id: 'metadata.dropdownPlaceholder' })}
        </option>
      )}
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.labelId
            ? formatMessage({ id: option.labelId })
            : option.label}
        </option>
      ))}
    </Form.Select>
  );
};

export default Select;
