import { lazy, Suspense } from 'react';
import Loader from '../Loader';

const ResultsContainer = () => {
  const Stats = lazy(() => import('./Stats'));
  return (
    <Suspense fallback={<Loader className="flex-grow-1 text-dark" />}>
      <Stats />
    </Suspense>
  );
};

export default ResultsContainer;
