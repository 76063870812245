import { useRecoilValue } from 'recoil';
import Header from './Header/Header';
import Intl from './Intl';
import FormsContainer from './Forms/FormsContainer/FormsContainer';
import 'react-slidedown/lib/slidedown.css';
import {
  fileInputCompleteState,
  filesState,
  payDataState,
} from '../atoms/files';
import ResultsContainer from './Results/ResultsContainer';

const GigEconomyUnwrapped = () => {
  const isFileInputComplete = useRecoilValue(fileInputCompleteState);
  const payData = useRecoilValue(payDataState);
  const files = useRecoilValue(filesState);

  return (
    <Intl>
      <div
        className={`${
          isFileInputComplete ? 'bg-grey' : 'bg-primary'
        } d-flex flex-column min-vh-100`}
      >
        <Header isDark={isFileInputComplete} />
        <div className="flex-grow-1 d-flex flex-column">
          {isFileInputComplete ? <ResultsContainer /> : <FormsContainer />}
        </div>
      </div>
      <div className="bg-dark text-white p-2">
        <h5 className="text-uppercase fw-bolder">Devtool</h5>
        <pre className="mb-0">
          {JSON.stringify({ files, payData }, null, 2)}
        </pre>
      </div>
    </Intl>
  );
};

export default GigEconomyUnwrapped;
