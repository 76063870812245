import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { Button } from 'react-bootstrap';
import { filesState } from '../../../atoms/files';
import styles from './InvoiceForm.module.scss';
import Progress from '../../Progress/Progress';
// import { zoneState } from '../../../atoms/metadata';
// import { TEST_DATA_KEY } from '../../../constants/env';
// import TestDataLoader from './TestDataLoader';
import InvoiceSummary from './InvoiceSummary/InvoiceSummary';

interface Props {
  onDrop: DropzoneOptions['onDrop'];
  onSubmit: () => void;
  progress?: number;
}

const InvoiceForm = ({ onDrop, onSubmit, progress = 0 }: Props) => {
  const files = useRecoilValue(filesState);
  // const zone = useRecoilValue(zoneState);

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop,
    accept: 'application/pdf',
  });

  return (
    <>
      {/* {zone.toLocaleLowerCase() === TEST_DATA_KEY && <TestDataLoader />} */}
      <Progress
        className="font-weight-800 font-size-18 text-dark border-bottom p-3"
        barClassName="bg-secondary"
        progress={progress}
      >
        {progress > 0 && progress < 100 ? (
          <FormattedMessage id="invoices.processing" />
        ) : (
          <FormattedMessage id="invoices.title" />
        )}
      </Progress>
      <div className="text-dark px-3 pt-3 h-100 d-flex flex-column">
        <p className="font-size-18 mb-0">
          <FormattedMessage id="invoices.subtitle" />
        </p>

        <div
          className={`${styles.drop} ${
            isDragAccept && styles.accept
          } my-3 p-3 flex-grow-1 d-flex flex-column justify-content-center align-items-center`}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <div className="m-0">
            <div className="d-none d-sm-flex flex-column text-center">
              <p className="font-weight-800 mb-1">
                <FormattedMessage id="invoices.upload.drop" />
              </p>
              <p>
                <FormattedMessage id="invoices.upload.browse" />
              </p>
            </div>
            <div className="d-sm-none">
              <FormattedMessage id="invoices.upload.mobile" />
            </div>
          </div>
        </div>
        {files.length > 0 && <InvoiceSummary />}
        <div className="d-flex justify-content-center">
          <Button
            onClick={onSubmit}
            disabled={files.length === 0}
            className="text-stratos font-size-stratos-body mb-3 text-lowercase w-100"
          >
            <FormattedMessage id="invoices.cta" />
          </Button>
        </div>
      </div>
    </>
  );
};

export default InvoiceForm;
