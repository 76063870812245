import { FormattedMessage } from 'react-intl';
import styles from './Footer.module.scss';
import itfLogo from '../../assets/itf.png';
import NewTab from '../NewTab';

const policyLinks = [
  ['https://iwgb.org.uk/page/privacy', 'footer.privacy'],
  ['https://iwgb.org.uk/page/data-protection', 'footer.dataProtection'],
  ['https://iwgb.org.uk/page/cookies', 'footer.cookies'],
  ['https://github.com/iwgb/roo-invoice-parser', 'footer.openSource'],
];

const Footer = () => (
  <div className="row py-5">
    <div className="col-12 d-flex justify-content-between">
      <div className="me-2">
        <p className="d-flex align-items-center">
          <NewTab href="https://iwgb.org.uk" titleMessage="footer.iwgb">
            <img
              alt="IWGB"
              src="https://cdn.iwgb.org.uk/assets/wordmark-white.png"
              className={styles.iwgb}
            />
          </NewTab>
          <span className="px-2">&times;</span>
          <NewTab href="https://itfglobal.org" titleMessage="footer.itf">
            <img alt="ITF" src={itfLogo} className={styles.itf} />
          </NewTab>
        </p>
        <p className="font-weight-600">
          <FormattedMessage
            id="footer.credit"
            values={{
              iwgb: (
                <NewTab className="text-white" href="https://iwgb.org.uk">
                  IWGB
                </NewTab>
              ),
              itf: (
                <NewTab className="text-white" href="https://itfglobal.org">
                  ITF
                </NewTab>
              ),
            }}
          />
        </p>
      </div>
      <div className="d-flex align-items-end text-end ms-2">
        <p className="font-weight-600">
          {policyLinks.map(([href, message]) => (
            <span key={message} className={styles.policyLink}>
              <NewTab className="text-white" href={href}>
                <FormattedMessage id={message} />
              </NewTab>
            </span>
          ))}
        </p>
      </div>
    </div>
  </div>
);

export default Footer;
