import { Spinner } from 'react-bootstrap';
import { Styled } from '../utils/types';

interface Props extends Styled {}

const Loader = ({ className = '' }: Props) => (
  <div
    className={`${className} d-flex align-items-center justify-content-center h-100`}
  >
    <Spinner animation="border" />
  </div>
);

export default Loader;
