import { ChangeEvent, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Languages from 'iso-639-1';
import { localeState } from '../../atoms/locale';
import { LOCALES } from '../../constants/intl';
import Select from '../Forms/Select';
import { Styled } from '../../utils/types';

const LOCALE_PICKER_ID = 'locale-picker';

interface Props extends Styled {}

const LanguageSelect = ({ className }: Props) => {
  const [currentLocale, setLocale] = useRecoilState<string>(localeState);

  const onSelectLocale = (locale: string) => () => setLocale(locale);

  const onDropdownChange = ({
    target: { value },
  }: ChangeEvent<HTMLSelectElement>) => setLocale(value);

  const options = useMemo(
    () =>
      LOCALES.map((locale) => ({
        value: locale,
        label: Languages.getNativeName(locale),
      })),
    []
  );

  return (
    <>
      <div className={`${className} d-none d-md-flex align-items-center`}>
        {LOCALES.map((locale) => (
          <Button
            key={locale}
            variant="link"
            onClick={onSelectLocale(locale)}
            className={`text-white ${
              locale === currentLocale ? 'fw-bold' : ''
            }`}
          >
            {Languages.getNativeName(locale)}
          </Button>
        ))}
      </div>
      <div className="d-flex d-md-none align-items-center">
        <label className="visually-hidden" htmlFor={LOCALE_PICKER_ID}>
          <FormattedMessage id="header.language" />
        </label>
        <Select<string>
          id={LOCALE_PICKER_ID}
          value={currentLocale}
          onChange={onDropdownChange}
          options={options}
          showPlaceholder={false}
        />
      </div>
    </>
  );
};

export default LanguageSelect;
