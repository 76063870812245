import { atom } from 'recoil';
import { Month } from '@iwgb/roo-invoice-parser';
import { SignedSerializedInvoice } from '../utils/types';

export const filesState = atom<SignedSerializedInvoice[]>({
  key: 'files',
  default: [],
});

export const payDataState = atom<Month[]>({
  key: 'payData',
  default: [],
});

export const fileInputCompleteState = atom<boolean>({
  key: 'fileInputComplete',
  default: false,
});
