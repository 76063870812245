import { lazy, Suspense } from 'react';
import HighlightText from '../../HighlightText/HighlightText';
import styles from './MetadataForm.module.scss';
import Loader from '../../Loader';

const MetadataForm = () => {
  const MetadataFormBody = lazy(() => import('./MetadataFormWrapper'));

  return (
    <div className="d-flex flex-column justify-content-between">
      <div className="py-4 px-3 px-sm-5 px-lg-4">
        <HighlightText
          topMessage="logo.top"
          bottomMessage="logo.bottom"
          highlightClassName={styles.highlight}
          bottomClassName={styles.bottom}
        />
      </div>
      <Suspense fallback={<Loader className="text-purple m-5" />}>
        <MetadataFormBody />
      </Suspense>
    </div>
  );
};

export default MetadataForm;
