import { chunk } from 'lodash';

export const batch = async <T, R>(
  items: T[],
  promise: (item: T) => Promise<R>,
  batchSize: number
): Promise<R[]> => {
  let results: R[] = [];
  // eslint-disable-next-line no-restricted-syntax -- includes inner await
  for (const itemBatch of chunk(items, batchSize)) {
    results = [
      ...results,
      // eslint-disable-next-line no-await-in-loop -- intentional
      ...(await Promise.all(itemBatch.map(async (item) => promise(item)))),
    ];
  }
  return results;
};

export const flatten = <T>(items: T[][]): T[] =>
  items.reduce((flattened, itemGroup) => [...flattened, ...itemGroup], []);
