import { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import sortBy from 'lodash/sortBy';
import { processInvoices } from '@iwgb/roo-invoice-parser';
import {
  fileInputCompleteState,
  filesState,
  payDataState,
} from '../../../atoms/files';
import { marketState, marketDataState } from '../../../atoms/metadata';
import {
  getUploadUrls,
  isDuplicate,
  parseUploadedInvoices,
  uploadAllToS3,
} from '../../../utils/invoice';
// import testData from '../../test-data';
import { SignedSerializedInvoice } from '../../../utils/types';
import InvoiceForm from './InvoiceForm';

const InvoiceFormWrapper = () => {
  const [courierId, setCourierId] = useState('');
  const [files, setFiles] = useRecoilState(filesState);
  const setPayData = useSetRecoilState(payDataState);
  const market = useRecoilValue(marketState);
  const marketData = useRecoilValue(marketDataState);
  const setFileInputComplete = useSetRecoilState(fileInputCompleteState);
  const [progress, setProgress] = useState(0);

  // useEffect(() => {
  //   if (zone.toLowerCase() === process.env.REACT_APP_TEST_DATA_KEY) {
  //     setFiles(testData.map(parseInvoiceDateTimes));
  //   }
  // }, []);

  const parseFiles = async (
    filesToParse: File[]
  ): Promise<[string, SignedSerializedInvoice[]]> => {
    setProgress(0);

    let newCourierId = courierId;
    if (newCourierId === '') {
      newCourierId = `C-${Date.now()}`;
    }

    const filesToUpload = await getUploadUrls(
      newCourierId,
      filesToParse.filter(({ type }) => type === 'application/pdf')
    );
    setProgress(10);

    await uploadAllToS3({
      files: filesToUpload,
      setProgress,
      baseProgress: 10,
      progressAmount: 50,
    });

    const results = await parseUploadedInvoices({
      invoiceIds: filesToUpload.map(({ id }) => id),
      courierId: newCourierId,
      marketCode: market.iso639,
      marketData,
      setProgress,
      baseProgress: 60,
      progressAmount: 40,
    });

    return [newCourierId, results];
  };

  const onDrop = async (acceptedFiles: File[]) => {
    const [newCourierId, invoices] = await parseFiles(acceptedFiles);

    const acceptedInvoices = invoices.reduce(
      (accepted: SignedSerializedInvoice[], invoice: SignedSerializedInvoice) =>
        isDuplicate(accepted, invoice.hash) ? accepted : [...accepted, invoice],
      [...files]
    );

    setCourierId(newCourierId);
    setFiles(sortBy([...files, ...acceptedInvoices], 'start'));

    setProgress(100);
  };

  const onSubmit = async () => {
    // post data
    setPayData(await processInvoices(files));
    setFileInputComplete(true);
  };

  return (
    <InvoiceForm onDrop={onDrop} progress={progress} onSubmit={onSubmit} />
  );
};

export default InvoiceFormWrapper;
