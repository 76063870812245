import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { filesState } from '../../../../atoms/files';

const InvoiceSummary = () => {
  const files = useRecoilValue(filesState);

  const totalShifts = useMemo(
    () =>
      files.reduce((shiftCount, { shifts }) => shiftCount + shifts.length, 0),
    [files]
  );

  return (
    <div className="mb-2">
      <FormattedMessage
        id="invoices.shiftCount"
        values={{
          shifts: <strong>{totalShifts}</strong>,
        }}
      />
    </div>
  );
};

export default InvoiceSummary;
