import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Styled } from '../utils/types';

interface Props extends Styled {
  href: string;
  titleMessage?: string;
  children?: ReactNode;
}

const NewTab = ({
  href,
  className = '',
  titleMessage = '',
  children = null,
}: Props) => {
  const { formatMessage } = useIntl();

  return (
    <a
      className={className}
      href={href}
      title={titleMessage ? formatMessage({ id: titleMessage }) : ''}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

export default NewTab;
