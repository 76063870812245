import { FormattedMessage } from 'react-intl';
import { Accordion } from 'react-bootstrap';
import InfoSection from './InfoSection';
import { arrayOf } from '../../../constants/array';

const Info = () => (
  <Accordion className="mt-5 mb-3">
    <InfoSection message="landing.uploadHelp.title">
      <>
        {arrayOf(3).map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key -- no available key
          <p key={i}>
            <FormattedMessage id={`landing.uploadHelp.${i + 1}`} />
          </p>
        ))}
      </>
    </InfoSection>
    <InfoSection message="landing.data.title">
      <p>
        <FormattedMessage id="landing.data.body" />
      </p>
    </InfoSection>
  </Accordion>
);

export default Info;
