import itfLogo from '../assets/itf.png';

export const ENGLISH = 'en';
export const FRENCH = 'fr';
export const ITALIAN = 'it';
export const DUTCH = 'nl';
export const PORTUGUESE = 'pt';
export const CHINESE = 'zh';

export const LOCALES = [ENGLISH, FRENCH, ITALIAN, DUTCH, PORTUGUESE, CHINESE];

const GBP = 'GBP';
const EUR = 'EUR';
const AUD = 'AUD';
const HKD = 'HKD';

interface Affiliate {
  name: string;
  logo: string;
}

export interface Market {
  name: string;
  iso639: string;
  currency: string;
  payThresholds: {
    living?: number;
    minimum?: number;
    committed: number;
    ceo: number;
  };
  affiliate?: Affiliate;
}

export const Markets: { [market: string]: Market } = {
  UNITED_KINGDOM: {
    name: 'UNITED_KINGDOM',
    iso639: 'GB',
    currency: GBP,
    payThresholds: {
      living: 950,
      minimum: 891,
      committed: 1500,
      ceo: 286832,
    },
  },

  BELGIUM: {
    name: 'BELGIUM',
    iso639: 'BE',
    currency: EUR,
    payThresholds: {
      committed: 1500,
      ceo: 343181,
    },
  },

  NETHERLANDS: {
    name: 'NETHERLANDS',
    iso639: 'NL',
    currency: EUR,
    payThresholds: {
      committed: 1500,
      ceo: 343181,
    },
  },

  FRANCE: {
    name: 'FRANCE',
    iso639: 'FR',
    currency: EUR,
    payThresholds: {
      committed: 1500,
      ceo: 343181,
    },
  },

  ITALY: {
    name: 'ITALY',
    iso639: 'IT',
    currency: EUR,
    payThresholds: {
      committed: 1500,
      ceo: 343181,
    },
  },

  AUSTRALIA: {
    name: 'AUSTRALIA',
    iso639: 'AU',
    currency: AUD,
    payThresholds: {
      minimum: 2033,
      living: 2500,
      committed: 2500,
      ceo: 533138,
    },
    affiliate: {
      name: 'TWU NSW',
      logo: 'https://twunsw.org.au/wp-content/uploads/2019/04/twunsw.monotone.png',
    },
  },

  HONG_KONG: {
    name: 'HONG_KONG',
    iso639: 'HK',
    currency: HKD,
    payThresholds: {
      minimum: 3750,
      committed: 4000,
      ceo: 2989822,
    },
  },

  IRELAND: {
    name: 'IRELAND',
    iso639: 'IE',
    currency: EUR,
    payThresholds: {
      minimum: 1050,
      committed: 1500,
      ceo: 343181,
    },
  },
};

export const DEFAULT_AFFILIATE: Affiliate = {
  name: 'ITF',
  logo: itfLogo,
};

export const DEFAULT_LOCALE = LOCALES[0];
