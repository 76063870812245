import { LOCALES } from '../constants/intl';

interface Langpack {
  [key: string]: string | Langpack;
}

interface FlatLangpack {
  [key: string]: string;
}

const flattenLangpack = (nested: Langpack, prefix: string = ''): FlatLangpack =>
  Object.entries(nested).reduce((flattened, [key, value]) => {
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    return typeof value === 'string'
      ? {
          ...flattened,
          [prefixedKey]: value,
        }
      : {
          ...flattened,
          ...flattenLangpack(value, prefixedKey),
        };
  }, {});

type LangpackLoaderObject = { [locale: string]: () => Promise<Langpack> };

const getLangpacks = () =>
  LOCALES.reduce(
    (langpackLoader: LangpackLoaderObject, locale: string) => ({
      ...langpackLoader,
      // eslint-disable-next-line prefer-template -- dynamic import doesn't allow template
      [locale]: () => import('../locales/' + locale + '.json'),
    }),
    {}
  ) as LangpackLoaderObject;

export const loadAndSetLangpack = (
  locale: string,
  set: (langpack: FlatLangpack) => void
): void => {
  (async () => {
    set(flattenLangpack(await getLangpacks()[locale]()));
  })();
};

export const getUrlLocale = (): string | null => {
  const matches = window.location.pathname.match(/^\/([a-z]{2})/);
  return matches && matches[1];
};
