import { atom, selector } from 'recoil';
import { MarketData } from '../components/Forms/MetadataForm/formConstants';
import { Market, Markets } from '../constants/intl';

export const marketState = atom<Market>({
  key: 'market',
  default: Markets.UNITED_KINGDOM,
});

export const zoneState = atom<string>({
  key: 'zone',
  default: '',
});

export const vehicleState = atom<string>({
  key: 'vehicle',
  default: '',
});

export const marketDataState = atom<MarketData>({
  key: 'marketData',
  default: {},
});

export const metadataCompleteState = selector<boolean>({
  key: 'metadataComplete',
  get: ({ get }) => !!(get(zoneState) && get(vehicleState)),
});
