import { ReactNode, useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import styles from './Progress.module.scss';
import { Styled } from '../../utils/types';

interface Props extends Styled {
  barClassName?: string;
  progress?: number;
  total?: number;
  children?: ReactNode;
}

const Progress = ({
  barClassName = '',
  progress = 0,
  total = 100,
  children = null,
  className = '',
}: Props) => {
  const progressPercent = useMemo(
    () => (100 / total) * progress,
    [progress, total]
  );

  return (
    <div>
      <div className="h-100">
        <div
          className={`${styles.bar} ${barClassName} h-100`}
          style={{ width: `${progressPercent}%` }}
        />
      </div>
      <div
        className={`${styles.children} position-relative d-flex justify-content-between align-items-center ${className}`}
      >
        <div>{children}</div>
        <div>
          {progress > 0 && progress < 100 && (
            <Spinner animation="border" size="sm" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Progress;
