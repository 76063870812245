import { useRecoilState } from 'recoil';
import { ReactNode, useEffect, useState } from 'react';
import useLocalStorage from 'react-use-localstorage';
import { Spinner } from 'react-bootstrap';
import { IntlProvider } from 'react-intl';
import { getUrlLocale, loadAndSetLangpack } from '../utils/intl';
import { localeState } from '../atoms/locale';
import { DEFAULT_LOCALE, LOCALES } from '../constants/intl';

interface Props {
  children?: ReactNode;
}

const Intl = ({ children = null }: Props) => {
  const [locale, setLocale] = useRecoilState<string>(localeState);
  const [langpack, setLangpack] = useState<{ [key: string]: string }>({});
  const [storedLocale, persistLocale] = useLocalStorage(
    'langpack',
    DEFAULT_LOCALE
  );
  const urlLocale = getUrlLocale();

  useEffect(() => {
    if (urlLocale && LOCALES.includes(urlLocale)) {
      setLocale(urlLocale);
      return;
    }
    if (storedLocale !== DEFAULT_LOCALE && LOCALES.includes(storedLocale)) {
      setLocale(storedLocale);
    }
  }, [setLocale, storedLocale, urlLocale]);

  useEffect(() => {
    window.history.replaceState({}, '', `/${locale}`);
    loadAndSetLangpack(locale, setLangpack);
    persistLocale(locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- must not call history api unnecessarily
  }, [locale]);

  return (
    <IntlProvider locale={locale} messages={langpack}>
      {Object.keys(langpack).length === 0 ? (
        <div className="vh-100 d-flex align-items-center justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        children
      )}
    </IntlProvider>
  );
};

export default Intl;
