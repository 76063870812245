import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import styles from './FormsContainer.module.scss';
import Footer from '../../Footer/Footer';
import MetadataForm from '../MetadataForm/MetadataForm';
import { metadataCompleteState } from '../../../atoms/metadata';
import InvoiceForm from '../InvoiceForm/InvoiceFormWrapper';
import Info from '../Info/Info';

const FormsContainer = () => {
  const isMetadataComplete = useRecoilValue(metadataCompleteState);

  return (
    <div
      className={`flex-grow-1 ${styles.background} d-flex flex-column text-white`}
    >
      <div className="container flex-grow-1 d-flex flex-column justify-content-between">
        <div className="row">
          <div className="col-lg-7">
            <h1 className="font-size-64 font-weight-800 text-uppercase py-3">
              <FormattedMessage id="landing.title" />
            </h1>
            <p className="font-size-28 font-weight-600">
              <FormattedMessage id="landing.upload" />
            </p>
            <p className="font-size-28 font-weight-600">
              <FormattedMessage id="landing.anonymous" />
            </p>
            <Info />
          </div>
          <div className="col-lg-5">
            <div className="bg-white d-flex flex-column h-100">
              {isMetadataComplete ? <InvoiceForm /> : <MetadataForm />}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default FormsContainer;
