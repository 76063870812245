import { RecoilRoot } from 'recoil';
import * as Fathom from 'fathom-client';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { LOCALES } from './constants/intl';
import { FATHOM_SCRIPT_URL, FATHOM_SITE_ID } from './constants/env';
import GigEconomyUnwrapped from './components/GigEconomyUnwrapped';
import 'tippy.js/dist/tippy.css';
import './App.scss';

const App = () => {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      Fathom.load(FATHOM_SITE_ID, {
        url: FATHOM_SCRIPT_URL,
        spa: 'auto',
      });
    }
  }, []);

  return (
    <>
      <Helmet
        defaultTitle="Deliveroo Invoice Tool · Riders Roovolt"
        titleTemplate="%s · Riders Roovolt"
      >
        <script
          src={`https://polyfill.io/v3/polyfill.min.js?${new URLSearchParams({
            features: LOCALES.map((locale) => `Intl.~locale.${locale}`).join(
              ','
            ),
          })}`}
        />
        {[32, 64, 96, 128].map((size) => (
          <link
            key={size}
            rel="icon"
            href={`https://cdn.iwgb.org.uk/assets/favicon-${size}.png`}
            sizes={`${size}x${size}`}
          />
        ))}
      </Helmet>
      <RecoilRoot>
        <GigEconomyUnwrapped />
      </RecoilRoot>
    </>
  );
};

export default App;
