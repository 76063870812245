import { useContext } from 'react';
import {
  Accordion,
  AccordionCollapseProps,
  AccordionContext,
  Button,
  useAccordionButton,
} from 'react-bootstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import styles from './InfoSection.module.scss';

interface Props {
  message: string;
  children: AccordionCollapseProps['children'];
}

const useOpenAccordion = (eventKey: string): boolean => {
  const { activeEventKey } = useContext(AccordionContext);
  return activeEventKey === eventKey;
};

const InfoSection = ({ message, children }: Props) => {
  const isOpen = useOpenAccordion(message);
  const onHeaderClick = useAccordionButton(message);

  return (
    <>
      <Button
        onClick={onHeaderClick}
        className="btn-reset d-flex align-items-center py-2 text-decoration-underline"
      >
        <div className={`${styles.icon} ${isOpen && styles.open} me-2`}>
          <Icon icon={faAngleRight} fixedWidth={true} />
        </div>
        <FormattedMessage id={message} />
      </Button>
      <Accordion.Collapse eventKey={message} className={styles.content}>
        <div className="ms-2 mt-2">{children}</div>
      </Accordion.Collapse>
    </>
  );
};

export default InfoSection;
