import http from 'axios';

const upload = (url: string, contentType: string, data: object = {}) =>
  http.put(url, data, {
    headers: {
      'x-amz-acl': 'private',
      'content-type': contentType,
    },
  });

export default { upload };
