import { FormattedMessage } from 'react-intl';
import styles from './HighlightWord.module.scss';
import { IntlMessage, Styled } from '../../utils/types';

interface Props extends Styled {
  containerClassName?: string;
  message: IntlMessage;
}

const HighlightWord = ({
  className = '',
  containerClassName = '',
  message,
}: Props) => (
  <div className={`${styles.container} ${containerClassName}`}>
    <span
      className={`${className} text-stratos text-uppercase ps-4 pe-2 ps-xxl-5 pe-xxl-3`}
    >
      <FormattedMessage id={message} />
    </span>
  </div>
);

export default HighlightWord;
